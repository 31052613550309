import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAndroid,
  faCss3Alt,
  faHtml5,
  faJsSquare,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-loaders'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      <div className="container  about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={'About Me'.split('')}
              idx={15}
            />
          </h1>
          <p>
            Currently pursuing a BS in Computer Engineering 
            from{' '}<a href="https://www.columbia.edu">Columbia University</a> 
            {' '}, 
            I'm a versatile problem-solver with a diverse skill set spanning 
            software development, cloud architecture, computer vision, and hardware 
            circuit design. My expertise ranges from building efficient, scalable software 
            to developing advanced machine learning models. I thrive in both business 
            and academic environments, collaborating on innovative solutions, 
            from research projects to commercial product development. 
          </p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faHtml5} color="#f1652a" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faReact} color="#61dafb" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faJsSquare} color="#efdb50" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faCss3Alt} color="#304cdc" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faDatabase} color="#eaa800" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faAndroid} color="#32de84" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="ball-scale-ripple-multiple" />
    </>
  )
}

export default About
